// New Layout for subscribed page
#content .container {
    .flex {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;

        .three-column-layout {
            width: 100%;

            @include breakpoint(to-medium) {
                text-align: center;
            }

            @include breakpoint(from-medium) {
                width: 33%;
            }

            .subscribe-cta-content {
                padding: 0 20px;
            }

            h4,
            p {
                margin-right: 0;
            }

            a {
                display: block;
                width: 100%;
                text-align: center;
                margin-left: auto;
                margin-right: auto;

                &.btn-primary {
                    margin-bottom: 20px;
                }
            }

            img.three-column-image {
                width: 100%;
                max-width: 100%;
                margin-bottom: 20px;

                @include breakpoint(to-medium) {
                    margin-bottom: 20px;
                    text-align: center;
                    left: auto;
                    transform: none;
                }
            }
        }

        &.subscribed-single {
            justify-content: flex-start;
            align-items: flex-end;

            img {
                margin-top: 30px;

                @include breakpoint(from-medium) {
                    width: auto;
                }
            }

            .content {
                margin-top: 20px;

                @include breakpoint(from-medium) {
                    width: 40%;
                    margin-left: 30px;
                    margin-top: 0;
                }
            }
        }
    }
}