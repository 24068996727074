// Mixins
	// Hidden Files
	%visuallyhidden {
	  margin: -1px;
	  padding: 0;
	  width: 1px;
	  height: 1px;
	  overflow: hidden;
	  clip: rect(0 0 0 0);
	  clip: rect(0, 0, 0, 0);
	  position: absolute;
	}

	// Font Awesome
	// @include font-awesome("\f18e");
	@mixin font-awesome($icon_code) {
		display: inline-block;
		font-family: FontAwesome;
		font-style: normal;
		font-weight: normal;
		line-height:1;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		content:"#{$icon_code}";
	}
	// Transition opacity
	@mixin transition-opacity($transition) {
		transition: opacity #{$transition}s ease-out;
		-moz-transition: opacity #{$transition}s ease-out;
		-webkit-transition: opacity #{$transition}s ease-out;
		-o-transition: opacity #{$transition}s ease-out;
	}

	// Responsive Embeds
	%embed-container {
		position: relative;
		padding-bottom: 56.25%;
		height: 0;
		overflow: hidden;
		max-width: 100%;
		height: auto;
	}
	%embed-container iframe,
	%embed-container object,
	%embed-container embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	// Grayscale filter
	%black-white {
		-moz-filter: url("data:image/svg+xml;utf8,<svg xmlns=\'https://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
	    -o-filter: url("data:image/svg+xml;utf8,<svg xmlns=\'https://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
	    -webkit-filter: grayscale(100%);
	    filter: gray;
	    //filter: url("data:image/svg+xml;utf8,<svg xmlns=\'https://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
	}

	// Simple Transitions
	%transition {
	  transition: 0.3s ease-in-out;
	}
	%transitionin {
	  transition: 0.2s ease;
	}

	// Shadows
	%dropshadow {
		box-shadow: 0 8px 6px -6px #666;
	}
	%dropshadowlight {
		box-shadow: 0 6px 4px -4px rgba(0,0,0,0.1);
	}
	%innershadow {
		box-shadow: inset 0 0 10px #020202;
	}
	%outershadow {
		box-shadow: 3px 3px 5px 6px #ccc;
	}

	// Text Shadows
	%textshadow {
		text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.4);
	}
	%textshadow-grey {
		text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.2);
	}
	%textshadow-white {
		text-shadow: 1px 1px 0 rgba(255,255,255,0.5);
	}

	// Opacity Mixin
	@mixin opacity($opacity) {
	  opacity: $opacity;
	  $opacity-ie: $opacity * 100;
	  filter: alpha(opacity=$opacity-ie); //IE8
	}

	// Underline
	@mixin underlineleft ( $underline: $base2-aqua, $padding: 22px ) {
	    border-width: 0 0 6px;
	    border-style: solid;
	    border-color: $underline;
	    width: 80%;
	    height: 1px;
	    content: '';
	    display: block;
	    position: absolute;
	    left: 0;
	    padding-top: $padding;
	    opacity: 0.4;
	}
	@mixin underline ( $underline: #dedede, $padding: 22px ) {
	    border-width: 0 0 1px;
	    border-style: solid;
	    border-color: $underline;
	    width: 20%;
	    height: 1px;
	    content: '';
	    display: block;
	    position: absolute;
	    left: 50%;
	    margin-left: -10%;
	    padding-top: $padding;
	    opacity: 0.8;
	}

	// Browser Specific mixins
	// IE10 Fix
	@mixin apply-to-IE {
		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			@content;
		}
		@media screen and (min-width:0\0) {
			@content;
		}
	}
	// IE Edge only
	@mixin ie-edge {
		@supports (-ms-ime-align:auto) {
			@content;
		}
	}

	// HTC only fix
	@mixin htc-only {
		@media screen and (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 3) {
				@content;
		}
	}

	.mobile-only {
		display: none;
	
		@include breakpoint(to-medium) {
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
		}
	}
	
	.desktop-only {
		@include breakpoint(to-medium) {
			display: none;
		}
	}