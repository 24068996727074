// Content Block under main image
#home-leadin {
	max-width: $content_width;
	@extend %clearfix;
	@extend %grid-container-collapse;
	height: auto;
	margin: 0 auto;
	padding: 20px;
	text-align: center;
	background-color: $base2-aqua;
	//background-image: url("../../images/white_bg.svg");
	//background-position: 0 80%;
	//background-repeat: no-repeat;
	//background-size: 16%;
	position: relative;
	z-index: 151;

	@include breakpoint(to-medium) {
		margin: 6px 6px 0;
	}

	&.nobanner {
		@include breakpoint(from-xlarge) {
			margin-top: 30px;
		}
	}
	@include breakpoint(from-xlarge) {
		margin-top: -30px;
	}

	&.base2-green {
		background-color: $base2-green;
	}
	&.base2-blue {
		background-color: $base2-blue;
	}
	&.base2-lime {
		background-color: $base2-lime;
	}
	&.base2-turquoise {
		background-color: $base2-turquoise;
	}

	@include breakpoint(from-medium) {
	//	padding: 50px;
	}

	h2, p {
		width: 100%;
		margin: 0 auto;

		@include breakpoint(from-medium) {
			width: 92%;
		}
	}

	a.btn-primary {
		background: none;
		@extend %transition;

		&:hover {
			background-color: rgba(255,255,255,0.3);
		}
	}

	h2 {
		color: $white;
		font-size: clamp(24px, calc(1rem + 1vw), 28px);
		margin-bottom: 66px;
		text-shadow: none;

		&:after {
			@include underline;
			border-color: $white;
			padding-top: 32px;
		}
	}
	p {
		color: $white;
		margin-bottom: 18px;
		width: 85%;
		font-size: 16px;
		line-height: 1.55;

		@include breakpoint(from-medium) {
			width: 72%;
		}
		@include breakpoint(to-small) {
			width: 100%;
		}
	}
}

// Community Columns
ul.columns {
	// Services Columns
	&.services {
		margin-bottom: 0;

		li {
			background-color: rgba(62, 197, 236, 1);
			background-image: url("../../images/white_bg.svg");
			background-position: 0 40%;
			background-repeat: no-repeat;
			background-size: 80%;
			width: 100%;
			position: relative;

			&:after {
				position:absolute;
				right:14px;
				bottom: 8px;
				@include font-awesome("\f105");
				z-index: 20;
				color: $white;
				opacity:0.7;
				font-size: 28px;
			}

			&.columntwo {
				background-color: rgba(0,173,187,1);// rgba(124, 205, 204, 1);
				margin-left: 0;
			}
			&.columnthree {
				background-color: rgba(85,167,154,1);//rgba(171,214,163,1);
				margin-left: 0;
			}

			.column-content {
				padding: 30px;
				color: $white;

				h3 {
					color: $white;
					font-size: clamp(26px, calc(1rem + 1vw), 32px);
					margin-bottom: 18px;
					text-shadow: none;
				}
				a {
					color: $white;
				}
				p.first {
					padding-top: 14px;
					border-top: 2px solid rgba(255,255,255,0.5);
				}
			}
		}
	}
}

.product.video {
	display: grid;
	background: $base2-aqua !important;

	.product-copy {
		color: $white;
		padding: 30px;
	}
	.video-embed-container {
		margin-bottom: 0;
		display: flex;
		flex: 1;
	}

	@include breakpoint(from-medium) {
		grid-template-columns: 1fr 1fr;
	}
}

// Testimonials Section
.testimonial {
	@include breakpoint(from-medium) {
		@include grid(50);
		float: left;
		padding: 0 16px;
	}

	&.home {
		margin-top: 20px;
		@include grid(100);
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
		align-items: stretch;
	}
}

.home-testimonial-carousel {
	.owl-stage {
		display: flex;

		.owl-item {
			display: flex;
			flex: 1;
			flex-grow: 1;
		}
	}
	.owl-controls {
		position: absolute;
		top: 40%;
		width: 100%;

		i {
			display: block !important;
		}
	}

	blockquote {
		small {
			padding: 0;
			margin-top: 10px;
		}

		img {
			max-height: 56px !important;
		}
	}

	.owl-prev {
		position: absolute;
		left: -28px;
	}

	.owl-next {
		position: absolute;
		right: -28px;
	}

	.owl-custom-prev:after,
	.owl-custom-next:after {
		display: block;
		position: relative;
		font-size: 18px;
		font-family: FontAwesome;
		color: $case-study-grey;
	}

	.owl-custom-prev:after {
		content: "\f053";
	}

	.owl-custom-next:after {
		content: "\f054";
	}

	.tooltip {
		top: -13px;
		left: 0;
		text-indent: 20px;
		width: 100%;
        display: block;
        position: absolute;
        height: 30px;
        line-height: 30px;
        background: $base2-blue;
        color: $white;
		padding-right: 20px;
        -webkit-transition: opacity 0.3s ease-in;
        transition: opacity 0.3s ease-in;
        opacity: 0;
	}

	a {
		&:hover {
			.tooltip {
				opacity: 1;
			}
		}
	}
}
blockquote.carousel {
	background: none;

	&.carousel {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: stretch;
		flex: 1;
		margin: 0;
		
		.testimonials-carousel-flex {
			background: $light-grey;
			position: relative;
			border-radius: 3px;
			display: flex;
			align-items: center;
			justify-content: stretch;
			padding: 26px;

			&:after,
			&:before {
				top: 100%;
				border: solid transparent;
				content: " ";
				height: 0;
				width: 0;
				position: absolute;
				pointer-events: none;
			}

			&:after {
				border-top-color: $light-grey;
				border-width: 10px;
				left: 10%;
				margin-left: -10px;
			}

			&:before {
			border-top-color: rgba(0, 0, 0, 0.01);
			border-width: 11px;
			left: 90%;
			margin-left: -11px;
			}
		}
		small {
			margin-top: auto;
		}
		p {
			display: flex;
			align-items: center;
			 padding: 0;
			font-size: 14px;
			line-height: 1.3;
			font-style: normal;
			color: $dark-grey;
			flex: 1;
			flex-grow: 1;
		}
		span.attribution {
			text-align-last: left;
			margin-top: 8px;
			color: $dark-grey;
		}
	}

	p {
    position: relative;
    color: #333;
	}
	a {
		color: #989898;
		padding-top: 12px;
		display: block;
	}
	small {
	    display: block;
	    padding: 12px 0 0 10px;
	    color: $dark-grey;
	    font-size: 11px;

	    &.customer-page {
	    	margin-top: -24px;
	    }

	    &:before {
	    	content: '';
	    }
	    img {
	    	margin-top: 12px;
    		max-width: 120px;
		}
	}
}
// banner link fix

.banner {
	a.btn-primary {
		background: none !important;
		@extend %transition;

		&:hover {
			background-color: rgba(255,255,255,0.3) !important;
		}
	}
}
