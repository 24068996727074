// Sidebar Accordions
#content {
    .container {
        //Sidebar
        .sidebar {
            @extend %grid-block;
            padding: 0;
            @include grid(25);

            @include breakpoint(to-medium) {
                @include grid(100);
            }

            &.left {
                float: left;
            }

            &.right {
                float: right;
                margin-top: 60px;
                overflow: visible;

                @include breakpoint(to-medium) {
                    margin-top: 0;
                }
            }

            &.large-sidebar {
                @include grid(40);
            }

            label {
                font-size: 14px;
                margin-bottom: 10px;
            }

            .sidebar-back-button {
                background: $base2-aqua;
                color: $white;
                display: block;
                height: 30px;
                line-height: 30px;
                padding-left: 30px;
                text-transform: uppercase;
                font-size: 12px;
                font-weight: bold;
                position: absolute;
                top: 0;
                left: 0;
                @include grid(25);

                &.right {
                    left: auto;
                    right: 0;
                }

                @include breakpoint(to-medium) {
                    @include grid(100);
                    position: relative;
                    margin-bottom: 20px;
                }

                @include breakpoint(to-small) {
                    margin-bottom: 8px;
                }

                &:hover {
                    background: $base2-blue;
                }

                &:after {
                    content: "\f053";
                    display: block;
                    font-family: FontAwesome;
                    color: $white;
                    font-size: 13px;
                    position: absolute;
                    left: 10px;
                    top: 1px;
                }
            }
        }

        .aside {
            @extend %grid-block;
            @include grid(75);
            margin-top: 0;

            &.left {
                @include breakpoint(from-medium) {
                    float: left;
                    padding: 0 20px 0 0;
                }
            }

            &.right {
                @include breakpoint(from-medium) {
                    float: right;
                    padding: 0 0 0 20px;
                }
            }

            @include breakpoint(to-medium) {
                @include grid(100);
                padding: 0;
            }

            .container {
                margin-top: 0;
            }

            &.margin-top {
                margin-top: 60px;
            }

            &.small-aside {
                @include grid(60);
            }
        }
        //Accordions
        .sidebar-accordion {
            width: 100%;
            margin: 0 0 30px 0;
            font-size: 16px;

            @include breakpoint(to-medium) {
                margin-bottom: 14px;
            }

            @include breakpoint(to-small) {
                margin-bottom: 8px;
            }

            &.active {
                .accordion-title:after {
                    @include font-awesome("\f077");

                    @include breakpoint(to-medium) {
                        @include font-awesome("\f078");
                    }
                }
            }

            .accordion-title {
                display: block;
                width: 100%;
                height: 60px;
                line-height: 60px;
                padding: 0 30px;
                background: $sidebar-title-bg;
                color: #404040;

                &:after {
                    position: absolute;
                    right: 30px;
                    top: 22px;
                    @include font-awesome("\f078");

                    @include breakpoint(to-medium) {
                        @include font-awesome("\f077");
                    }

                    font-size: 18px;
                    z-index: 20;
                    color: #404040;
                    opacity: 0.6;
                }
            }

            .accordion-content {
                &.start-closed {
                    display: none;
                }

                @include breakpoint(to-medium) {
                    display: none;
                }

                ul {
                    margin: 0;
                    padding: 0;
                    width: 100%;

                    li {
                        list-style: none;
                        width: 100%;
                        margin: 0;
                        padding: 0;

                        a {
                            display: block;
                            width: 100%;
                            line-height: 28px;
                            padding: 15px 30px;
                            background: $white;
                            border-bottom: 2px solid #f0f0f0;
                            color: #404040;

                            &.active {
                                color: #007387;
                                font-weight: bold;

                                &:before {
                                    display: block;
                                    position: absolute;
                                    left: 0;
                                    top: 0;
                                    width: 10px;
                                    height: 60px;
                                    content: "";
                                    border-left: 6px solid #007387;
                                }
                            }
                        }
                    }
                }

                .video-link {
                    position: relative;
                    display: block;
                    background: $white;
                    z-index: 11;

                    .play-video {
                        height: 60px;
                        width: 60px;
                        position: absolute;
                        border: 4px solid $white;
                        border-radius: 40px;
                        z-index: 22;
                        left: 50%;
                        top: 50%;
                        margin-top: -30px;
                        margin-left: -30px;

                        &:after {
                            display: block;
                            content: "\f04b";
                            font-family: FontAwesome;
                            color: $white;
                            font-size: 22px;
                            text-align: center;
                            top: 16px;
                            position: relative;
                            left: 2px;
                        }
                    }

                    img {
                        width: 100%;
                        margin-bottom: 0;
                    }
                }

                .content {
                    background: $white;
                    padding: 30px;
                    font-size: 14px;

                    img {
                        width: 100%;
                        margin-bottom: 20px;
                        height: auto;
                    }

                    .sidebar-news-item {
                        width: 100%;
                        padding-bottom: 15px;
                        margin-bottom: 15px;
                        border-bottom: 1px solid #e3e3e3;

                        &:last-child {
                            border-bottom: none;
                            margin: 0;
                            padding: 0;
                        }

                        .news-date {
                            display: block;
                            width: 100%;
                            font-size: 13px;
                            font-style: italic;
                            margin: 0 0 5px 0;
                            color: $mid-grey;
                        }

                        h4 {
                            margin-top: 10px;
                            font-size: 15px;
                        }
                    }
                }

                .pdf-wrapper {
                    .container {
                        margin: 10px 0 0;
                        padding: 16px 28px;
                        border-radius: 40px;

                        a {
                            text-transform: none;
                            padding-left: 46px;
                        }
                    }
                }
            }

            &.downloads {
                img {
                    border: 1px solid #ededed;
                    margin-bottom: 0 !important;
                    @extend %transition;
                    max-width: 100%;

                    @include breakpoint(to-medium) {
                        display: none;
                    }

                    &:hover {
                        opacity: 0.7;
                    }
                }
            }
        }

        // Sidebar content blocks
        .sidebar-block {
            width: 100%;
            margin: 0 0 30px 0;
            font-size: 15px;
            color: $base2-heading;
        }
    }
}