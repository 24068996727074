.table-responsive {
	width: 100%;
	.table {
		width: 100%;
		border: 1px solid $light-grey;
		margin-bottom: 30px;

		th, td {
			padding: 8px 16px;

			@include breakpoint(to-medium){
				padding: 4px 8px;
			}
			@include breakpoint(to-small){
				padding: 0 2px;
			}
			border: 1px solid $light-grey;

			ul {
				margin-bottom: 0 !important;
				li {
					margin: 0 !important;
				}
			}
		}
		td {
			font-size: 12px;

			@include breakpoint(to-small){
				font-size: 10px;
			}
		}
		th {
			text-align: left;
			color: $white;
			background-color: $dark-grey;
			letter-spacing: 1px;
		}
		&.odd-even {
			tr{
				&:nth-child(even) {
					background: rgba($light-grey, 0.2);
				}
			}
		}
		&.pci-table {
			-webkit-box-shadow: 0 8px 6px -6px rgba(black,0.3);
			box-shadow: 0 8px 6px -6px rgba(black,0.3);

			tr {
				td {
					&:first-child {
						width: 5%;
					}
					&::nth-child(2) {
						width: 55%;
					}
					&:last-child {
						width: 45%;

						@include breakpoint(to-small) {
							width: 55%
						}
					}
				}
			}
		}
	}
}

// New table
.table-md {
    width: 100%;
    text-align: left;
    border: 1px solid $light-grey;
    border-bottom: none;
    border-right: none;

    th {
        font-weight: 600;
        background-color: rgba($light-grey, 0.5);
    }

    td,
    th {
        width: 50%;
        padding: 8px;
        border-bottom: 1px solid $light-grey;
        border-right: 1px solid $light-grey;

        p,
        ul {
            margin-bottom: 0 !important;
            padding-bottom: 0 !important;
        }
    }
}