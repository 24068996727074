//offer grid
.grid-before {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px;
}
.grid-offer {
    display: grid;
    justify-content: space-between;

    @include breakpoint(from-medium) {
        grid-template-columns: 52px 1fr 1fr 1fr;
    }

    &.no-border {
        @include breakpoint(from-medium) {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }

    &.second-grid {
        border-top: 1px solid $base2-blue;
        border-left: 1px solid $base2-blue;
        border-right: 1px solid $base2-blue;

        @include breakpoint(to-medium) {
            border-bottom: 1px solid $base2-blue;
        }
    }

    &.third-grid {
        border-left: 1px solid $base2-blue;
        border-right: 1px solid $base2-blue;
        border-bottom: 1px solid $base2-blue;
    }

    &.arrow-grid {
        border-left: 1px solid $base2-blue;
        border-right: 1px solid $base2-blue;

        div {
            padding: 8px 24px;
        }
    }

    div {
        padding: 24px;

        &.second-column {
            background-color: #f9f9f9;
            border-left: 1px solid #ededed;
            border-right: 1px solid #ededed;

            @include breakpoint(to-medium) {
                border-left: none;
                border-right: none;
                border-top: 1px solid #ededed;
                border-bottom: 1px solid #ededed;
            }
        }

        @include breakpoint(to-small) {
            padding: 14px;
        }

        &.sideways {
            display: flex;
            padding: 10px 0;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            //border-right: 1px solid #ededed;
            background-color: #f8f8f8;
            border-style: solid;
            border-color: #ededed;
            border-width: 0 1px;
            font-size: 85%;

            @include breakpoint(to-medium) {
                border-right: none;
                border-left: none;
            }

            img {
                writing-mode: vertical-rl;
                max-width: 34px;

                @include breakpoint(to-medium) {
                    writing-mode: horizontal-tb;
                }
            }

            h4 {
                text-align: center;
                padding-bottom: 12px;
                margin-bottom: 0;
                padding-top: 12px;
                writing-mode: vertical-rl;
                font-weight: normal;
                text-transform: uppercase;
                font-size: 14px;
                letter-spacing: 0.5px;

                @include breakpoint(to-medium) {
                    writing-mode: horizontal-tb;
                    padding-top: 0;
                    padding-bottom: 0;
                }
            }
        }
    }

    .image-wrapper {
        padding: 12px;
        display: flex;
        justify-content: center;

        img {
            max-height: 62px;
        }
    }

    .content-wrapper {
        padding: 12px;
        text-align: center;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }
}

.image-wrapper {
    &.down-arrows {
        img {
            max-height: 22px;
            margin-top: 12px;
            margin-bottom: 24px;
        }
    }

    &.outside-grid {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        h4 {
            color: #464646;
            margin-top: 0;
            margin-bottom: 0;
        }
    }
}
